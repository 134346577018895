<template>
  <div class="signup-body">
    <div class="error container">
      <div class="row align-items-center">
        <div class="col-md-6 px-5">
          <img src="/images/citronworks_4042.png" class="w-100">
        </div>
        <div class="col-md-6">
          <h1 class="bold mb-3">Page not found</h1>
          <h5>The page that you are looking for doesn't exist.</h5>
          <h5>Click here to go back or contact our support to report this.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>